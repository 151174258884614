import React from "react";
import { Row, Col } from "react-bootstrap";
import { StaticQuery, graphql, Link } from "gatsby";
import BlogPostCard from "../components/frontpage/partials/BlogPostCard";
import Layout from "../components/layout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../utils/font-awesome";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";

const RICHTEXT_OPTIONS = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <>{children}</>;
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return <a href={node.data.uri}>{children}</a>;
    },
  },
};

export default function Blogs() {
  return (
    <StaticQuery
      query={graphql`
        {
          blogposts: allContentfulBlogPostPage(
            sort: { fields: [date], order: ASC }
          ) {
            nodes {
              id
              slug
              image {
                fluid(quality: 100, maxHeight: 800, maxWidth: 2400) {
                  src
                }
              }
              createdAt
              header
              subheader
              mainText {
                mainText
                json
              }
            }
          }
          CardsContainer: contentful5LastBlogs {
            header
          }
        }
      `}
      render={(data) => {
        return (
          <Layout className="bg-light">
            <div className="blogposts-block">
              <Link to="/" className="backIcon">
                <FontAwesomeIcon
                  icon={["fas", "arrow-left"]}
                  title="arrow-left"
                />{" "}
              </Link>
              <h3 className="title">Blogs</h3>
              <Row>
                {data.blogposts.nodes.length ? (
                  data.blogposts.nodes.map((blogPost, i) => {
                    return (
                      <Col className="card-wrapper">
                        <Link to={`/Blogs/${blogPost.slug}`} key={i}>
                          <BlogPostCard
                            imgSrc={blogPost.image.fluid.src}
                            header={blogPost.header}
                            subheader={blogPost.subheader}
                            key={`blog-${blogPost.id}`}
                            slug={blogPost.slug}
                            contentStart={documentToReactComponents(
                              blogPost.mainText.json,
                              RICHTEXT_OPTIONS
                            )}
                            createdAt={blogPost.createdAt}
                          />
                        </Link>
                      </Col>
                    );
                  })
                ) : (
                    <div> No blogs available</div>
                  )}
              </Row>
            </div>
          </Layout>
        );
      }}
    />
  );
}
