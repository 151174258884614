import React from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../utils/font-awesome";

export default function BlogPostCard(props) {
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let contentCreatedDate = new Date(props.createdAt);
  let monthIndex = contentCreatedDate.getMonth();

  let date =
    months[monthIndex] +
    " " +
    contentCreatedDate.getDate() +
    "," +
    contentCreatedDate.getFullYear();

  return (
    <Card key={props.slug} className="square parent_content">
      <Card.Img variant="top" src={props.imgSrc} />

      <Card.Body className="content">
        <Card.Title>{props.header}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          {props.subheader}
        </Card.Subtitle>
        <p class="card-date">
          <FontAwesomeIcon
            icon={["far", "calendar-alt"]}
            title="calendar-alt"
          />{" "}
          {date}
        </p>
        <Card.Text className="contentStart clampContent">
          {" "}
          {props.contentStart}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
